<template>
  <div
    class="orderdetail"
    v-loading="loading"
    :element-loading-text="lang_data == 1 ? '拼命加载中' : 'Loading'"
  >
    <div class="content">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/home' }">{{
          lang_data == 1 ? "订单管理" : "Order Management"
        }}</el-breadcrumb-item>
        <el-breadcrumb-item>{{
          lang_data == 1 ? "订单详情" : "order details"
        }}</el-breadcrumb-item>
      </el-breadcrumb>

      <div class="detail-bottom" v-if="!loading">
        <ul class="sub-left">
          <li class="flexdr flexd-x-c flexSB">
            <span class="subtitle">
              <span class="txt">{{
                lang_data == 1 ? "基本信息" : "Basic Information"
              }}</span>
            </span>

            <!-- 未进入生产中取消 -->
            <div>
              <el-button
                type="primary"
                round
                v-if="
                  !(
                    info.produce_status_show.value === 0 &&
                    info.order_receive_status_show.value == 10
                  )
                "
                @mouseenter.native="enter"
                @mouseleave.native="leave"
                size="small"
              >
                {{ lang_data == 1 ? "生产进度" : "Production progress" }}
              </el-button>
              <template v-if="info.status_show.value == 5">
                <!-- 订单完成状态案或者取消状态才显示按钮组 -->
                <el-button
                  size="small"
                  round
                  @click="showReWork()"
                  v-if="this.isRepair"
                >
                  {{ lang_data == 1 ? "返工" : "Rework" }}
                </el-button>
              </template>
            </div>
          </li>
          <li>
            <el-form label-width="100px" label-position="left" class="miniSzie">
              <el-row>
                <el-col :span="12">
                  <el-form-item
                    :label="lang_data == 1 ? '订单号' : 'Order number'"
                    >{{ info.order_no }}</el-form-item
                  >
                  <el-form-item
                    :label="lang_data == 1 ? '订单类型' : 'Order Type'"
                    >{{ info.order_type_show.label }}</el-form-item
                  >
                  <el-form-item :label="lang_data == 1 ? '工厂' : 'Factory'">{{
                    info.factory_name
                  }}</el-form-item>
                  <el-form-item :label="lang_data == 1 ? '医生' : 'Doctor'">{{
                    info.doctor_name
                  }}</el-form-item>
                  <el-form-item :label="lang_data == 1 ? '患者' : 'Case'">{{
                    info.patient_name
                  }}</el-form-item>
                  <el-form-item :label="lang_data == 1 ? '性别' : 'Gender'">
                    <span v-if="info.patient_gender == 1">男</span>
                    <span v-else-if="info.patient_gender == 2">女</span>
                  </el-form-item>
                  <el-form-item
                    :label="lang_data == 1 ? '颈部牙齿颜色' : 'Shade-Gingival'"
                    >{{ info.up_color_name }}</el-form-item
                  >
                  <el-form-item
                    :label="lang_data == 1 ? '体部牙齿颜色' : 'Shade-Body'"
                    >{{ info.color_name }}</el-form-item
                  >
                  <el-form-item
                    :label="lang_data == 1 ? '切端牙齿颜色' : 'Shade-Incisal'"
                    >{{ info.low_color_name }}</el-form-item
                  >
                  <el-form-item
                    :label="lang_data == 1 ? '基牙颜色' : 'Shade-Abutment'"
                    >{{ info.butment_color_name }}</el-form-item
                  >
                </el-col>
                <el-col :span="12">
                  <el-form-item
                    :label="lang_data == 1 ? '下单码' : 'Order code'"
                    >{{ info.order_code }}</el-form-item
                  >
                  <el-form-item :label="lang_data == 1 ? '状态' : 'State'">{{
                    info.status_show.label
                  }}</el-form-item>
                  <el-form-item :label="lang_data == 1 ? '诊所' : 'Client'">{{
                    info.clinic_name
                  }}</el-form-item>
                  <el-form-item
                    :label="
                      lang_data == 1
                        ? '医生联系方式'
                        : 'Doctor contact information'
                    "
                    >{{ info.doctor_mobile }}</el-form-item
                  >
                  <el-form-item :label="lang_data == 1 ? '年龄' : 'Age'">{{
                    info.patient_age ? info.patient_age : ""
                  }}</el-form-item>
                  <el-form-item :label="lang_data == 1 ? '销售' : 'Sales'">{{
                    info.sales_staff_name
                  }}</el-form-item>
                  <el-form-item
                    :label="lang_data == 1 ? '总价' : 'Total price'"
                    >{{ info.order_amount }}</el-form-item
                  >

                  <el-form-item
                    :label="lang_data == 1 ? '下单时间' : 'Creation time'"
                    >{{
                      $moment(info.create_order_time * 1000).format(
                        "YYYY-MM-DD hh:mm"
                      )
                    }}
                  </el-form-item>

                  <el-form-item
                    :label="lang_data == 1 ? '接单时间' : 'Order time'"
                    v-if="info.order_receive_time > 0"
                    >{{
                      $moment(info.order_receive_time * 1000).format(
                        "YYYY-MM-DD hh:mm"
                      )
                    }}</el-form-item
                  >
                  <el-form-item
                    :label="lang_data == 1 ? '发货时间' : 'Delivery time'"
                    v-if="info.express_send_time > 0"
                    >{{
                      $moment(info.express_send_time * 1000).format(
                        "YYYY-MM-DD hh:mm"
                      )
                    }}</el-form-item
                  >
                  <el-form-item
                    :label="lang_data == 1 ? '承诺发货时间' : 'Commitment time'"
                    v-if="info.factory_id == 7"
                    >{{
                      info.express_promise_time
                        ? $moment(info.express_promise_time * 1000).format(
                            "YYYY-MM-DD hh:mm"
                          )
                        : null
                    }}</el-form-item
                  >
                  <el-form-item
                    :label="lang_data == 1 ? '预计发货时间' : 'estimated time'"
                    v-if="info.factory_id == 7"
                    >{{
                      info.express_excepted_time
                        ? $moment(info.express_excepted_time * 1000).format(
                            "YYYY-MM-DD hh:mm"
                          )
                        : null
                    }}</el-form-item
                  >
                </el-col>
              </el-row>
              <el-row>
                <el-form-item
                  :label="lang_data == 1 ? '医生要求' : 'Doctor request'"
                >
                  <div class="requirement">{{ info.requirement }}</div>
                </el-form-item>
              </el-row>
            </el-form>
          </li>
          <li>
            <span class="subtitle">
              <span class="txt">{{ lang_data == 1 ? "项目" : "Project" }}</span>
            </span>
            <span class="fr"
              >{{ lang_data == 1 ? "合计" : "Total" }}：{{
                info.order_amount
              }}</span
            >
          </li>
          <li>
            <el-table
              header-row-class-name="item-table"
              row-class-name="item-row"
              class="itemTable"
              :data="project_list"
              style="width: 730px"
              border
            >
              <el-table-column
                prop="project_name"
                width="200px"
                :label="lang_data == 1 ? '项目名称' : 'Item'"
              >
              </el-table-column>
              <el-table-column
                width="88"
                prop="category_1_name"
                :label="lang_data == 1 ? '项目大类' : 'Category'"
              >
              </el-table-column>
              <el-table-column
                :label="
                  lang_data == 1
                    ? '牙位/上下颌'
                    : 'Tooth bit / On the lower jaw'
                "
                :width="toothStates == 3 ? '350px' : '200px'"
              >
                <template slot-scope="scope">
                  <template v-if="scope.row.up_down_tooth.length > 0">
                    {{
                      scope.row.up_down_tooth
                        .map((val) => {
                          return {
                            1: lang_data == 1 ? "上颌" : "Upper jaw",
                            2: lang_data == 1 ? "下颌" : "The jaw",
                          }[val];
                        })
                        .join("、")
                    }}
                  </template>
                  <template v-else>
                    <ToothPosition
                      type="preview"
                      :tooth_top_left="scope.row.tooth_top_left"
                      :tooth_top_right="scope.row.tooth_top_right"
                      :tooth_bottom_left="scope.row.tooth_bottom_left"
                      :tooth_bottom_right="scope.row.tooth_bottom_right"
                      :toothStates="toothStates"
                    ></ToothPosition>
                  </template>
                </template>
              </el-table-column>

              <el-table-column
                prop="count"
                :label="lang_data == 1 ? '数量' : 'Quantity'"
              ></el-table-column>
              <el-table-column
                prop="price"
                :label="lang_data == 1 ? '单价' : 'Unit price'"
              >
              </el-table-column>
              <el-table-column
                prop="row_total"
                :label="lang_data == 1 ? '小计' : 'Subtotal'"
              >
              </el-table-column>
            </el-table>
          </li>
          <li>
            <span class="subtitle">
              <span class="txt">{{
                lang_data == 1 ? "牙模数据" : "Dental mold data"
              }}</span>
            </span>
          </li>
          <li class="flexdr" v-if="info.submit_type == 1">
            <div style="color: #999">
              {{ lang_data == 1 ? "快递" : "Express delivery" }}
            </div>
            <div class="filelist">
              <span
                >{{ lang_data == 1 ? "快递公司" : "Courier company" }}：<span
                  style="font-weight: 550"
                  >{{ info.stl_express_company_name }}</span
                ></span
              >
              <span
                >{{ lang_data == 1 ? "单号" : "Single number" }}：<span
                  style="font-weight: 550"
                  >{{ info.stl_express_no }}</span
                ></span
              >
            </div>
          </li>
          <li class="flexdr" v-else>
            <div style="color: #999">
              {{
                lang_data == 1 ? "口扫数据" : "Intra Oral Scanner/ Digital File"
              }}
            </div>
            <ul class="filelist">
              <li>
                <input
                  type="file"
                  hidden
                  ref="stl_upload_up"
                  @change="onSelectStl_up"
                />

                <span class="mr10">{{
                  lang_data == 1 ? "上颌口扫" : "Maxillary mouth scan"
                }}</span>
                <el-input
                  :placeholder="
                    lang_data == 1
                      ? '请选择口扫数据文件'
                      : 'Please select oral scan'
                  "
                  :value="stlFile_up ? stlFile_up.name : info.stl_file_up"
                  style="width: 200px"
                  size="mini"
                  readonly
                ></el-input>
                <el-button
                  size="mini"
                  class="ml10"
                  @click="$refs.stl_upload_up.click()"
                  >{{ lang_data == 1 ? "上传" : "Upload" }}</el-button
                >
              </li>
              <li>
                <input
                  type="file"
                  hidden
                  ref="stl_upload_down"
                  @change="onSelectStl_down"
                />
                <span class="mr10">{{
                  lang_data == 1 ? "下颌口扫" : "Jaw scan"
                }}</span>
                <el-input
                  :placeholder="
                    lang_data == 1
                      ? '请选择口扫数据文件'
                      : 'Please select oral scan'
                  "
                  :value="stlFile_down ? stlFile_down.name : info.stl_file_down"
                  style="width: 200px"
                  size="mini"
                  readonly
                ></el-input>
                <el-button
                  size="mini"
                  class="ml10"
                  @click="$refs.stl_upload_down.click()"
                  >{{ lang_data == 1 ? "上传" : "Upload" }}</el-button
                >
              </li>
              <li>
                <input
                  type="file"
                  hidden
                  ref="stl_upload_down"
                  @change="onSelectStl_bite"
                />
                <span class="mr10">{{
                  lang_data == 1 ? "咬合口扫" : "Bite scan"
                }}</span>
                <el-input
                  :placeholder="
                    lang_data == 1
                      ? '请选择口扫数据文件'
                      : 'Please select oral scan'
                  "
                  :value="stlFile_bite ? stlFile_bite.name : info.stl_file_bite"
                  style="width: 200px"
                  size="mini"
                  readonly
                ></el-input>
                <el-button
                  size="mini"
                  class="ml10"
                  @click="$refs.stl_upload_down.click()"
                  >{{ lang_data == 1 ? "上传" : "Upload" }}</el-button
                >
              </li>
              <template v-if="info.order_port_scanning">
                <el-divider content-position="left">{{
                  lang_data == 1
                    ? "以下为第三方平台获取口扫"
                    : "The following are third-party mouth scans"
                }}</el-divider>
                <li>
                  <span class="mr10" style="padding-right: 12px">{{
                    lang_data == 1 ? "第三方" : "Third party"
                  }}</span>
                  <el-input
                    v-model.trim="info.order_port_scanning.type_show.label"
                    style="width: 300px"
                    size="mini"
                    readonly
                  ></el-input>
                </li>
                <li>
                  <span class="mr10">{{
                    lang_data == 1 ? "案例名称：" : "Case name"
                  }}</span>
                  <el-input
                    v-model.trim="info.order_port_scanning.name"
                    style="width: 300px"
                    size="mini"
                    readonly
                  ></el-input>
                </li>
                <li>
                  <span class="mr10">{{
                    lang_data == 1 ? "案例地址：" : "Case address"
                  }}</span>
                  <el-input
                    v-model.trim="info.order_port_scanning.url"
                    style="width: 300px"
                    size="mini"
                    readonly
                  ></el-input>
                </li>
                <li style="color: #e6a23c">
                  <span v-if="info.scanning_data_status_show.value == 2">
                    {{ info.scanning_data_status_show.label }}（{{
                      info.scanning_data_reason
                    }}）</span
                  >
                </li>
              </template>
            </ul>
            <!-- <div class="flex-1">
            <button class="line-button fr">上传牙模</button>
          </div> -->
          </li>
          <li>
            <span class="subtitle">
              <span class="txt">{{
                lang_data == 1 ? "随单配件" : "Enclosed items"
              }}</span>
            </span>
          </li>
          <li class="flexdr">
            <span :style="accessoryInfo ? 'color: #999' : ''">{{
              accessoryInfo
            }}</span>
          </li>
          <template v-if="info.process_technics.length > 0">
            <li>
              <span class="subtitle">
                <span class="txt">{{
                  lang_data == 1 ? "加工工艺" : "Processing technology"
                }}</span>
              </span>
            </li>
            <li class="flexdr">
              <span
                v-for="(item, i) in info.process_technics"
                :key="item.id"
                style="color: #000"
              >
                {{ item.name }}
                <span style="color: #7c7e84">{{ item.technics[0].name }}</span>
                <img
                  v-if="item.technics[0].icon"
                  style="
                    width: 14px;
                    line-height: 14px;
                    vertical-align: middle;
                    margin-top: -2px;
                    padding-left: 4px;
                  "
                  :src="item.technics[0].icon"
                  alt=""
                /><span v-if="i != info.process_technics.length - 1">，</span>
              </span>
            </li>
          </template>
          <li>
            <span class="subtitle">
              <span class="txt">{{
                lang_data == 1 ? "附件" : "Accessories"
              }}</span>
            </span>
          </li>
          <li>
            <AttachFile
              v-if="info.attachment.length > 0"
              :dataList="info.attachment"
            ></AttachFile>
            <span style="color: #999" v-else>暂无附件</span>
          </li>
        </ul>

        <div class="sub-right">
          <ul>
            <template v-if="info.reback_record_list.length > 0">
              <li>
                <span class="subtitle">
                  <span class="txt">{{
                    lang_data == 1 ? "退/返记录" : "Return/Return record"
                  }}</span>
                </span>
              </li>
              <li>
                <div class="bg-white">
                  <div
                    class="flexdr flexd-x-c"
                    v-for="(item, index) in info.reback_record_list"
                    :key="index"
                  >
                    <div>
                      {{ item.type_show.label
                      }}{{ lang_data == 1 ? "单" : "One" }}
                    </div>
                    <div class="ml20 flex-1">
                      <el-link
                        class="blue"
                        type="primary"
                        @click="
                          $router.push({
                            path: '/afterSalesDetails',
                            query: {
                              rebackid: item.new_order_id,
                              id: item.id,
                            },
                          })
                        "
                        >{{ item.reback_no }}
                        {{
                          item.rework_status_show.label
                            ? item.rework_status_show.label
                            : item.reback_status_show.label
                        }}</el-link
                      >
                    </div>
                  </div>
                </div>
              </li>
            </template>
            <li>
              <span class="subtitle">
                <span class="txt">{{
                  lang_data == 1 ? "收货地址" : "Address"
                }}</span>
              </span>
            </li>
            <li>
              <div class="bg-white">
                {{ info.receiver_name }}
                <span class="c666 ml20">{{ info.receiver_mobile }}</span
                ><br />
                {{ info.receiver_address }}
              </div>
            </li>
            <li>
              <span class="subtitle">
                <span class="txt">{{
                  lang_data == 1 ? "设计方案" : "Design"
                }}</span>
              </span>
            </li>
            <li>
              <div class="bg-white design">
                <dl v-if="DesignInfo">
                  <dd>
                    <span class="de-t">
                      {{ lang_data == 1 ? "STL方案" : "STL Program" }}</span
                    >
                    <div>
                      <div v-for="item in DesignInfo.stl_files" :key="item.url">
                        <span>{{ item.name }}</span>
                        <el-link
                          type="primary"
                          class="ml10"
                          @click="clickView(item)"
                          >查看</el-link
                        >
                        <el-link
                          type="primary"
                          class="ml10"
                          @click="downloadFile(item.url, item.name)"
                          >下载</el-link
                        >
                      </div>
                      <!-- <el-link
                        class="blue"
                        @click="desginshow = true"
                        type="primary"
                        >{{ lang_data == 1 ? "查看方案" : "Check" }}</el-link
                      > -->
                    </div>
                  </dd>
                  <dd>
                    <span class="de-t">{{
                      lang_data == 1 ? "图片" : "Images"
                    }}</span>
                    <AttachFile
                      viewtype="photo"
                      :dataList="DesignInfo.photos"
                    ></AttachFile>
                  </dd>
                  <dd>
                    <span class="de-t">{{
                      lang_data == 1 ? "备注" : "Remark"
                    }}</span
                    ><span class="flex-1">{{ DesignInfo.remark }}</span>
                  </dd>
                  <dd>
                    <span class="de-t">{{
                      lang_data == 1 ? "附件" : "Appendix"
                    }}</span>
                    <AttachFile
                      v-if="
                        DesignInfo &&
                        DesignInfo.files &&
                        DesignInfo.files.length > 0
                      "
                      :dataList="DesignInfo.files"
                    ></AttachFile>
                    <span v-else style="color: #999">暂无附件</span>
                  </dd>
                </dl>
                <dl v-else class="c999">
                  {{ lang_data == 1 ? "暂无数据" : "No data" }}
                </dl>
              </div>
            </li>
            <template v-if="info.express_send_type > 0">
              <li>
                <span class="subtitle">
                  <span class="txt">{{
                    lang_data == 1 ? "发货信息" : "Delivery information"
                  }}</span>
                </span>
              </li>
              <li class="bg-white c999" v-if="info.express_send_type == 2">
                {{
                  lang_data == 1
                    ? "销售派件，无物流"
                    : "Sales delivery, no logistics"
                }}
              </li>
              <li v-if="info.express_order.tracking_no">
                <div class="SFinfo">
                  <span>{{
                    lang_data == 1 ? "快递公司：" : "Courier Company"
                  }}</span
                  ><span>{{ info.express_order.name }}</span>
                </div>
                <div class="SFinfo">
                  <span>{{
                    lang_data == 1 ? "快递单号：" : "Single Number"
                  }}</span
                  ><span>{{ info.express_order.tracking_no }}</span>
                </div>
                <div class="expressInfo mt20">
                  <expressInfo
                    :express_order_id="info.express_order.id"
                  ></expressInfo>
                </div>
              </li>
            </template>
          </ul>
        </div>

        <Processes
          v-if="processesShow"
          :orderinfo="info"
          @mouseover="enter"
          @mouseleave="leave"
        ></Processes>
        <DesignBox
          :datainfo="datainfo"
          v-if="desginshow"
          @cancel="desginshow = false"
        ></DesignBox>
        <Repair
          :source_order="info"
          :datainfo="info"
          v-if="repairShow"
          :repairShow="repairShow"
          @cancel-event="repairShow = false"
          :type="2"
        ></Repair>
      </div>
    </div>
    <!-- 消息 -->
    <div class="message" @click="msgboxShow = true">
      <el-badge :is-dot="unreadNum ? true : false" class="item">
        <i class="el-icon-chat-dot-round"></i>
      </el-badge>
    </div>
    <Msgbox
      v-if="msgboxShow"
      :msgboxShow="msgboxShow"
      :orderids="[$route.query.id]"
      @cancel="msgboxShow = false"
    />
  </div>
</template>
<script>
import ToothPosition from "@/components/toothPosition.vue";
import expressInfo from "@/components/expressInfo.vue";
import AttachFile from "@/components/attachFile.vue";
import Processes from "@/components/processes.vue";
import DesignBox from "@/components/DesignBox.vue";
import Repair from "@/components/repair.vue";
import Msgbox from "@/components/msgbox.vue";
export default {
  name: "detail",
  components: {
    ToothPosition,
    expressInfo,
    AttachFile,
    Processes,
    DesignBox,
    Repair,
    Msgbox,
  },
  async mounted() {
    await this.getToothStates();
    if (this.$route.query.id) {
      this.orderid = parseInt(this.$route.query.id);
    }
    await this.getDetail();
    this.getDesignInfo();
  },
  watch: {
    messageList(data) {
      this.unreadNum = false;
      data.forEach((item) => {
        if (item.order_id == this.orderid) {
          this.unreadNum = true;
        }
      });
    },
  },
  computed: {
    accessoryInfo() {
      if (this.info && this.info.accessories_material) {
        let list = this.info.accessories_material.map((item) => {
          if (item.type == 1) {
            return `${item.name} ${item.content}`;
          } else {
            return `${item.name} ${item.number}`;
          }
        });
        if (list.length == 0) return this.lang_data == 1 ? "暂无随单配件" : "";
        return list.join("，");
      }
      return this.lang_data == 1 ? "暂无随单配件" : "";
    },
    messageList() {
      return this.$store.state.wsUnreadCount.arr;
    },
  },
  data() {
    return {
      lang_data: localStorage.lang_data || 1,
      info: {},
      project_list: [],
      orderid: "",
      stlFile_down: null,
      stlFile_up: null,
      stlFile_bite: null,
      DesignInfo: {},
      processesShow: false,
      desginshow: false,
      loading: true,
      timer: null,
      repairOption: {
        show: false,
        type: 1, //1返工，2退货
      },
      repairShow: false,
      isRepair: true,
      isReback: true,
      craftList: [],
      process_technic_ids: [],
      toothStates: "",
      msgboxShow: false,
      unreadNum: false,
      datainfo: {},
    };
  },
  methods: {
    clickView(item) {
      this.datainfo = {
        orderno: this.info.order_no,
        name: this.info.patient_name,
        url: item.url,
      };
      this.desginshow = true;
    },
    async downloadFile(path, name) {
      const blob = await this.$axios.get(path, {
        responseType: "blob",
      });
      let blob_url = URL.createObjectURL(blob);
      let link = document.createElement("a");
      link.href = blob_url;
      link.download = name;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(blob_url);
    },
    async getToothStates() {
      return this.$axios.post("/ucenter/getToothShowType").then((res) => {
        if (res.code != 1) {
          this.$message.error(res.msg);
          return;
        } else {
          this.toothStates = res.data;
        }
      });
    },
    getDesignInfo() {
      let params = {
        order_id: this.orderid,
      };
      this.$axios.get("/orders/orders/getDesign", { params }).then((res) => {
        if (res.code != 1) {
          this.$message.error(res.msg);
          return;
        } else {
          this.DesignInfo = res.data;
        }
      });
    },
    async getDetail() {
      this.loading = true;
      let params = {
        id: this.orderid,
      };

      return this.$axios
        .get("/orders/orders/getDetail", { params })
        .then((res) => {
          if (res.code != 1) {
            this.$message.error(res.msg);
            return;
          } else {
            this.loading = false;
            this.info = res.data;
            this.project_list = this.info.project_list.map((item) => {
              return {
                is_expire_timeout: item.is_expire_timeout,
                project_id: item.project_id,
                project_name: item.project_name,
                input_type: item.input_type,
                // 牙位
                tooth_top_left: item.tooth_top_left + "",
                tooth_top_right: item.tooth_top_right + "",
                tooth_bottom_left: item.tooth_bottom_left + "",
                tooth_bottom_right: item.tooth_bottom_right + "",
                // 上下颌
                up_down_tooth: item.tooth_type
                  ? item.tooth_type.split(",")
                  : [],

                price: item.price,
                count: item.quantity,
                row_total: item.row_total,
                category_1_id: item.category_1_id,
                category_1_name: item.category_1_name,
              };
            });
            this.rebackInfo();

            // ModalTool.create("MessageIMBell", {
            //   orderinfo: {
            //     order_id: this.info.id,
            //     order_no: this.info.order_no,
            //     order_code: this.info.order_code,
            //   },
            // });
          }
        });
    },
    rebackInfo() {
      //返工状态：1：待寄件，2：待接单，3：已接单，4：生产中，5：待发货，6：已发货，7：返工完成，10：已取消 rework_status_show
      //退货状态：1待寄件，2等待厂家签收，3等待厂家处理退货，4退货完成，5已取消，6已拒绝 reback_status_show
      //is_expire_timeout 1已过期 0是未过期
      if (
        this.info.reback_record_list &&
        this.info.reback_record_list.length > 0
      ) {
        this.info.reback_record_list.map((item) => {
          //返工中不显示 按钮
          if (
            item.type == 2 &&
            [1, 2, 3, 4, 5, 6].includes(item.rework_status_show.value)
          ) {
            this.isRepair = false;
            this.isReback = false;
          }
          //退货完成 不显示
          if (
            item.type == 1 &&
            ![5, 6].includes(item.reback_status_show.value)
          ) {
            this.isRepair = false;
            this.isReback = false;
          }
        });
      }
      //全部产品过质保卡年限则不显示返工按钮
      if (this.info.is_rework_on == 0) {
        this.isRepair = false;
      }
      if (this.info.is_reback_on == 0) {
        this.isReback = false;
      }
    },
    async uploadFile(key) {
      let data = {};
      if (key == "up") {
        data = {
          order_id: this.orderid,
          stl_file_up: await this.upload(this.stlFile_up),
        };
      } else if (key == "down") {
        data = {
          order_id: this.orderid,
          stl_file_down: await this.upload(this.stlFile_down),
        };
      } else if (key == "bite") {
        data = {
          order_id: this.orderid,
          stl_file_bite: await this.upload(this.stlFile_bite),
        };
      }

      let res = await this.axios.post("/orders/orders/uploadStlFile", data);
      if (res.code == 1) {
        this.$message.success(res.msg);
      } else {
        this.$message.error(res.msg);
      }
    },
    upload(file) {
      return new Promise((resolve) => {
        this.$getuploadsign(4, file).then((res) => {
          if (res.res.code != 1) {
            this.$message(res.res.msg);
            return;
          }
          resolve(res.url);
        });
      });
    },
    // 选择口扫数据文件
    onSelectStl_down(e) {
      let files = e.target.files;
      if (files.length == 0) return;
      this.stlFile_down = files[0];
      e.target.value = "";
      this.uploadFile("down");
    },
    onSelectStl_up(e) {
      let files = e.target.files;
      if (files.length == 0) return;
      this.stlFile_up = files[0];
      e.target.value = "";
      this.uploadFile("up");
    },
    onSelectStl_bite(e) {
      let files = e.target.files;
      if (files.length == 0) return;
      this.stlFile_bite = files[0];
      e.target.value = "";
      this.uploadFile("bite");
    },
    showReWork() {
      this.repairShow = true;
    },
    enter() {
      if (this.timer) clearTimeout(this.timer);
      this.processesShow = true;
    },
    leave() {
      this.timer = setTimeout(() => {
        this.processesShow = false;
      }, 400);
    },
  },
};
</script>
<style lang="scss" scoped>
.orderdetail {
  width: 100%;
  height: calc(100% - 70px);
  overflow: auto;
  .content {
    width: 1300px;
    margin: 0px auto;
    padding: 20px 0;
    .detail-bottom {
      display: flex;
    }
    .sub-left {
      padding: 20px 0;
      margin-right: 66px;
      flex: 1;
      li {
        padding-bottom: 20px;
        &:first-child {
          margin-top: 0;
        }
      }
      .filelist {
        margin-left: 36px;
        display: flex;
        flex-direction: column;
      }
    }
    .sub-right {
      padding: 32px 24px;
      padding-top: 30px;
      width: 455px;
      min-height: 500px;
      background: #f5f6f6;
      > ul > li {
        margin-top: 25px;
        &:first-child {
          margin-top: 0;
        }
      }
      .design {
        dd {
          margin-bottom: 15px;
          display: flex;
        }
        .de-t {
          width: 80px;
          display: inline-block;
          color: #666666;
        }
      }
      .SFinfo {
        margin-left: 15px;
        font-size: 16px;
        margin-bottom: 10px;
        span:first-child {
          margin-right: 22px;
        }
      }
    }
    .routelist {
      margin-left: 50px;
      height: 250px;
      overflow-y: auto;
    }
  }
}
.message {
  font-size: 25px;
  position: fixed;
  bottom: 30%;
  right: 100px;
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #409eff;
  color: #fff;
}
.bg-white {
  background-color: #fff;
  padding: 16px;
  line-height: 20px;
}
.expressInfo {
  background-color: #fff;
  padding: 13px 0 14px 13px;
  height: 250px;
  overflow-y: auto;
}

.requirement {
  line-height: 24px;
  margin-top: 8px;
}
</style>
<style lang="scss">
.orderdetail {
  .el-form-item {
    margin-bottom: 0px;
  }
  .el-form-item__content,
  .el-form-item__label {
    line-height: 30px;
  }
  .el-form-item__label {
    color: #999;
  }
  .el-form-item__content {
    font-weight: 550;
  }
}
</style>
